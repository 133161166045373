export const LANDING = '/';

export const HOME = '/home';
export const PROFILE = '/profile';

export const CARD_CREATE = '/cards';
export const CARD_EDIT = '/cards/:id';

export const MATCHES = '/matches';
export const MATCHES_ID = '/matches/:id';
export const MATCHES_SEARCH = '/matches/search';
export const MATCHES_SEARCH_ID = '/matches/search/:id';

export const HOT_SCRIMPS = '/hotscrimps';
export const LOGGED_OUT_HOT_SCRIMPS = '/tastyscrimps';

export const PLAYERS_LIST = '/player/list';
export const PLAYER_PROFILE = '/player/profile';

export const TEAMS_LIST = '/team/list';
export const TEAM_PROFILE = '/team/SCR-TID-';
export const TEAM_PROFILE_ID = '/team/SCR-TID-:id';
export const TEAM_ACCEPT_INVITE = '/team/invite/';
export const TEAM_ACCEPT_INVITE_PAGE = '/team/invite/:id';

export const ONBOARDING = '/welcome';

export const PLAYERBOARD = '/playerbowl';

export const LOGIN = '/login';

export const IMPRINT = '/imprint';
export const PRIVACY = '/privacy';
