import React from 'react';

// Libraries
import {
  Router,
  Route,
  Switch,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import stats from 'analytics/matomo';
import { HelmetProvider, Helmet } from 'react-helmet-async';

// Constants
import * as routes from 'constants/routes';

// Components
import Landing from './components/layout/Landing';

const history = stats.connectToHistory(createBrowserHistory());

stats.push(['requireConsent']);
stats.push(['enableHeartBeatTimer']);
stats.push(['trackPageView']);

const App = () => (
  <Router history={history}>
    <HelmetProvider>
      <Helmet>
        <title>Scrimps - Never scrim alone</title>
      </Helmet>

      <div className="columns is-mobile">

        <div className="column p-0" id="contentWrapper">
          <Switch>
            <Route
              exact
              path={routes.LANDING}
              component={() => <Landing />}
            />
          </Switch>
        </div>
      </div>

    </HelmetProvider>
  </Router>
);

export default App;
