import React, { useState } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

// Assets
import icon from 'assets/images/base/icon_no_text.png';

const Landing = () => {
  const { t } = useTranslation(['general', 'hotscrimps', 'slips', 'playerboard']);

  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const { post } = useAPI();

  const _setQuestion = (_question) => {
    setQuestion(_question);
  };

  const getAnswer = async () => {
    try {
      setDisabled(true);
      setStatus('');
      setAnswer('');

      const _answer = await post('/answer', { question });

      setAnswer(_answer);
      setDisabled(false);
    } catch (e) {
      console.log(e);
      setDisabled(false);
      setAnswer('');
      setStatus('Anfrage konnte nicht bearbeitet werden. Versuche es später noch einmal.');
    }
  };

  return (
    <>
      <div className="is-min-fullheight">

        <div className="columns is-centered is-vcentered is-min-fullheight">
          <div className="column is-8">

            <div className="has-text-centered">

              <img
                src={icon}
                alt="Scrimps"
                className="mb-5"
              />
              <p
                className="is-size-5 mb-3"
              >
                Stelle deine Frage über das Nitro-League Regelwerk!
              </p>
              <div className="is-flex">
                <input
                  className="input"
                  type="text"
                  placeholder="Frage"
                  value={question}
                  disabled={disabled}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      getAnswer();
                    }
                  }}
                  onChange={(e) => _setQuestion(e.target.value)}
                />

                <button
                  className={`button is-primary ml-3 ${disabled ? 'is-loading' : ''}`}
                  type="button"
                  onClick={getAnswer}
                  disabled={disabled}

                >
                  Frage stellen
                </button>
              </div>

              <div className="">
                {status}
              </div>

              <div className="has-height-300 has-border has-background-grey-darker br-5 mt-4 has-text-left p-4 has-text-white has-overflow-y-auto">
                <p
                  className="mb-3"
                >
                  Antwort:
                </p>
                {answer}
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default Landing;
