import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hasData: false,
  isLoading: true,
  name: '',
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSession: (state, action) => ({
      ...state,
      hasData: true,
      isLoading: false,
      ...action.payload,
    }),
    clearSession: () => initialState,
  },
});

export const {
  setSession,
  clearSession,
} = sessionSlice.actions;

export default sessionSlice.reducer;
